export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3117478842241061',
  agentpwd : '3117@1061$',
  apikey : 'f1e7ad3fe5a9081acdbdb68d61386f2c',
  url : 'https://bkbusiness.afrikpay.com',
  id : 'bkbusiness.afrikpay.com',
  accepturl : 'https://bkbusiness.afrikpay.com/payment/web/success',
  cancelurl : 'https://bkbusiness.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};